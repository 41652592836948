import dynamic from 'next/dynamic';
import { Box, Spinner } from 'theme-ui';
import { useProduct } from '@backpackjs/storefront';
import { useProductRecommendations } from '@hooks';

const ProductSwiper = dynamic(
  () => import('@snippets').then((module) => module.ProductSwiper),
  { ssr: false, suspense: false }
);

export const Recommendations = ({
  theme,
  inView,
  tag,
  limit,
  slidesPerViewMobile,
}) => {
  const product = useProduct();
  const { recommendations } = useProductRecommendations({
    tag: tag || 'customers-also-bought',
    limit,
    productId: product?.legacyResourceId || '',
  });

  if (recommendations && !recommendations.length) return null;

  const singleSlideOnMobile = slidesPerViewMobile === 'one';
  return (
    <Box sx={theme.content}>
      {inView && recommendations?.length > 0 ? (
        <ProductSwiper
          products={recommendations}
          isProductRecs
          singleSlideOnMobile={singleSlideOnMobile}
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '500px',
          }}
        >
          <Spinner sx={theme.spinner} />
        </Box>
      )}
    </Box>
  );
};
