import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slider = {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pt: [72, 88],
    };

    this.thumbsSwiper = (itemsLength) => ({
      bottom: ['30%', '20%'],
      mt: [32, 40],
      cursor: 'pointer',
      maxWidth: [`${itemsLength + 10}%`, `${itemsLength}%`],
      maxHeight: ['50px', '100px'],
    });
  })()
);
