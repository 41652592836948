import { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import { useAddToCart } from '@templates/product/Main/Details/AddToCart/useAddToCart';

import { themed } from './QuickAdd.theme';
import { QuickAddSize } from './QuickAddSize';
import { QuickAddWaistInseam } from './QuickAddWaistInseam';

export const QuickAdd = themed(({ theme, selectedProduct, props }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);

  const { addToCart } = useAddToCart({
    selectedVariant,
    product: selectedProduct,
  })[1];

  const preOrder = selectedProduct?.tags?.includes('pre-order');

  const variants = useMemo(() => {
    return selectedProduct?.variants
      ? [...selectedProduct.variants].sort((a, b) => a.position - b.position)
      : [];
  }, [selectedProduct?.id]);

  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedProduct?.handle,
    withInventory: false,
  });

  const addItem = useCallback(async () => {
    await addToCart();
  }, [selectedVariant]);

  useEffect(() => {
    addItem();
  }, [selectedVariant]);

  const options =
    selectedProduct?.grouping?.optionsMap &&
    Object.keys(selectedProduct?.grouping?.optionsMap);
  const showQuickAddWaist =
    options?.length &&
    options?.includes('Waist') &&
    options?.includes('Inseam');
  const showQuickAddSize =
    options?.length &&
    (options?.includes('Size') ||
      options?.includes('Amount') ||
      options?.includes('Waist'));

  const handleQuickAdd = (variant) => {
    if (variant?.id === selectedVariant?.id) {
      addToCart();
    } else {
      setSelectedVariant(variant);
    }
  };

  const hideQuickAdd = useMemo(() => {
    return selectedProduct?.tags?.includes('hide-quick-add');
  }, [selectedProduct?.id]);

  if (hideQuickAdd) return null;

  return (
    <Flex data-comp={QuickAdd.displayName} sx={theme.container} {...props}>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          opacity: 1,
          display: 'flex',
          justifyContent: 'center',
          transition: 'opacity 300ms ease 0s',
          '@media screen and (min-width: 480px)': {
            ':hover': { opacity: 0.0 },
          },
        }}
      >
        <Text variant="text.sm" sx={theme.firstText}>
          {preOrder ? 'Pre-order' : '+ Quick Add'}
        </Text>
      </Box>

      {showQuickAddWaist && (
        <QuickAddWaistInseam
          theme={theme}
          variants={variants}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          addToCart={addItem}
          handleQuickAdd={handleQuickAdd}
          inventory={inventory}
          inventoryFetched={inventoryFetched}
          selectedProduct={selectedProduct}
        />
      )}
      {showQuickAddSize && (
        <QuickAddSize
          theme={theme}
          variants={variants}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          addToCart={addItem}
          handleQuickAdd={handleQuickAdd}
          inventory={inventory}
          inventoryFetched={inventoryFetched}
        />
      )}
    </Flex>
  );
});

QuickAdd.displayName = 'QuickAdd';
