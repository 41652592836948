import { useRef, useState, useEffect } from 'react';
import { Box, Container, Spinner } from 'theme-ui';
import { Link, ShopifyMedia } from '@snippets';
import { useBreakpoint, useBreakpointValue } from '@hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { themed } from './Media.theme';
import { QuickAdd } from '../QuickAdd';

import 'swiper/css/pagination';

import 'swiper/css/bundle';
import 'swiper/css';

function handleMedia(allMedia, invertImages) {
  if (!allMedia) return null;
  const mediaCopy = [...allMedia];
  if (invertImages && mediaCopy.length > 1) {
    [mediaCopy[0], mediaCopy[1]] = [mediaCopy[1], mediaCopy[0]];
  }

  return mediaCopy;
}

export const Media = themed(
  ({
    theme,
    selectedProduct,
    invertImages = false,
    handleClick,
    onMouseEnter = () => null,
    onMouseLeave = () => null,
    productUrl,
  }) => {
    const [hovered, setHovered] = useState(false);
    const videoRef = useRef();
    const [swiper, setSwiper] = useState();

    const media = selectedProduct?.media;
    const firstMedia = media?.[0];
    const secondMedia = media?.[1];

    const [defaultMedia, hoverMedia] = invertImages
      ? [secondMedia, firstMedia]
      : [firstMedia, secondMedia];

    const showQuickAdd = useBreakpointValue([false, true]);

    const { s, m, _m } = useBreakpoint();
    const isMobile = s || m || _m;

    const allMedia = handleMedia(media, invertImages);

    useEffect(() => {
      if (!swiper || !swiper.initialized) return;

      try {
        swiper.on('slideChange', (swiper) => {
          if (!swiper) return;
          const is3DModel =
            media?.[swiper.realIndex]?.mediaContentType === 'MODEL_3D';
          if (is3DModel) {
            swiper.allowTouchMove = false;
          } else {
            swiper.allowTouchMove = true;
          }
          swiper.update();
        });
      } catch (error) {}
    }, [swiper]);

    useEffect(() => {
      if (hovered) {
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    }, [hovered]);

    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Link
          data-comp={Media.displayName}
          href={productUrl}
          sx={theme.link}
          onClick={handleClick}
        >
          <Box
            data-comp={Media.displayName}
            sx={{
              ...theme.wrapper,
              ':before': {
                content: '""',
                paddingBottom: `${(1 / 0.75) * 100}%`,
                width: 0,
              },
            }}
            onMouseEnter={() => {
              if (isMobile) return;
              onMouseEnter();
              setHovered(true);
            }}
            onMouseLeave={() => {
              if (isMobile) return;
              onMouseLeave();
              setHovered(false);
            }}
          >
            {/* For Mobile: Swiper component */}
            {isMobile ? (
              <Container
                sx={{
                  '.swiper-pagination-bullet': {
                    backgroundColor: '#CDD0D5',
                    width: '4px',
                    height: '4px',
                  },
                  '.swiper-pagination-bullet-active': {
                    backgroundColor: '#CDD0D5',
                    width: '6px',
                    height: '6px',
                  },
                  '.swiper-pagination': {
                    textAlign: 'right',
                    paddingRight: '20px',
                    alignItems: 'center',
                  },
                }}
              >
                <Swiper
                  data-comp="MediaSwiper"
                  centeredSlides
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  init={false}
                  // // preloadImages={false}
                  onSwiper={setSwiper}
                  slidesPerView={1}
                  freeMode
                  // // scrollbar={{ draggable: true }}
                  loop
                  loopedSlides={1}
                  initialSlide={0}
                  sx={{
                    width: '100%',
                  }}
                >
                  {allMedia?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <ShopifyMedia media={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Container>
            ) : (
              <>
                {/* hover media */}
                {hoverMedia && !isMobile ? (
                  <Box
                    data-comp={`${Media.displayName}Hover`}
                    sx={{
                      ...theme.hoverMedia,
                      opacity: hovered ? 1 : 0,
                      transition: 'opacity .3s ease-in-out',
                      '@media screen and (max-width: 479px)': {
                        display: 'none',
                      },
                    }}
                  >
                    <ShopifyMedia
                      media={hoverMedia}
                      autoplay={false}
                      ref={videoRef}
                    />
                  </Box>
                ) : null}

                {/* default media */}
                <Box
                  data-comp={`${Media.displayName}Default`}
                  sx={{
                    ...theme.media,
                    transition: 'opacity .2s ease-in-out',
                    '@media screen and (min-width: 480px)': {
                      opacity: hovered && hoverMedia ? 0 : 1,
                    },
                  }}
                >
                  {defaultMedia ? (
                    <ShopifyMedia media={defaultMedia} />
                  ) : (
                    <Spinner sx={theme.spinner} />
                  )}
                </Box>
              </>
            )}
          </Box>
        </Link>

        {showQuickAdd && !isMobile && (
          <QuickAdd selectedProduct={selectedProduct} />
        )}
      </Box>
    );
  }
);

Media.displayName = 'Media';
