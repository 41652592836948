import { promoBar } from './promoBar';
import { rotatingBar } from './rotatingBar';
import { menu } from './menu';
import { linksList } from './linksList';
import { salesLinks } from './salesLinks';
import { userLocationModal } from './userLocationModal';

// header
export default {
  label: 'Header Settings',
  name: 'header',
  component: 'group',
  fields: [
    {
      label: 'Enable Transparent Header on Homepage',
      name: 'enableTransparentBackground',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Enable Transparent Header on Homepage Mobile',
      name: 'enableTransparentBackgroundMobile',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    rotatingBar,
    promoBar,
    salesLinks,
    menu,
    linksList,
    userLocationModal,
  ],
  defaultValue: {
    enableTransparentBackground: false,
    enableTransparentBackgroundMobile: false,
    promoBar: {
      enabled: true,
      closable: true,
      duration: 3,
      speed: 0.75,
      messages: [
        {
          color: '#FFFEF9',
          background: '#113034',
          copy: '<p><b>SPECIAL SALE: 20% off all bikes. No code needed.</b> | Free shipping on orders over $300<p>',
          link: {
            text: '',
            url: '',
          },
        },
      ],
    },
  },
};
