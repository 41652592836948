import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: ['block', 'none'],
    };

    this.nav = {
      gap: 5,
      overflowX: 'auto',
      overflowY: 'hidden',
      px: 5,
      '::-webkit-scrollbar': {
        display: 'none',
      },
    };

    this.navItem = {
      overflow: 'hidden',
      position: 'relative',
      flexShrink: '0',
    };

    this.picture = ({ overlayActive }) => ({
      width: '100px',
      height: '100px',
      borderRadius: '100%',
      ':after': {
        content: "''",
        position: 'absolute',
        background: overlayActive
          ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%)'
          : null,
        borderRadius: '5px',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    });

    this.navText = ({ contentPosition = 'below' }) => ({
      fontSize: 0,
      lineHeight: '12px',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 500,
      letterSpacing: '1px',
      zIndex: 1,
      maxWidth: '100px',
      flex: 1,
      // mt: 'auto',
      ...(contentPosition === 'below'
        ? {
            position: 'static',
            color: 'text',
            px: 0,
            pt: 2,
            display: 'flex',
            alignItems: 'flex-end',
          }
        : {
            position: 'absolute',
            color: 'white',
            pt: 0,
            height: '100%',
            width: '100%',
            a: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              height: '100%',
              width: '100%',
              px: 6,
              pb: 6,
            },
          }),
    });
  })()
);
